import HttpService from '@/services/HttpService';
const getHospitals = async ({ postal, insurance, brand }) => {
    const { data } = (await HttpService.post('/clientcontroller/salesfunnelclient/ophalenziekenhuizen', {
        Locatie: postal,
        Pakket: insurance,
        Propositie: brand ?? 'BAP'
    }));
    if (data.isSucces === false) {
        throw new Error('Clientcontroller ophalenziekenhuizen returned isSucces -> false');
    }
    return data;
};
export default {
    getHospitals
};
