import AppRoot from '@/AppRoot.vue';
import SfStoreContextPlugin from '@/lib/SfStoreContextPlugin';
import EmbeddedAppPlugin from '@/lib/EmbeddedAppPlugin';
import SitecoreJssStorePlugin from '@/lib/SitecoreJssStorePlugin';
import { createRouter } from '@/router';
import createStore from '@/store';
import componentFactory from '@/temp/componentFactory';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import { createApp as createVueApp, createSSRApp, h } from 'vue';

export function createApp(appContext, initialState, isSSR) {
    const vueOptions = {
        render: () => h(AppRoot)
    };
    const app = isSSR ? createSSRApp(vueOptions) : createVueApp(vueOptions);
    const router = createRouter(app, appContext, isSSR);

    app.use(router);
    app.use(EmbeddedAppPlugin, { appContext });
    app.use(SitecoreJssStorePlugin);
    app.use(SitecoreJssPlaceholderPlugin, { componentFactory });

    const store = createStore(app);
    app.use(store);
    app.use(SfStoreContextPlugin, { store });

    // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
    if (initialState) {
        app.config.globalProperties.$jss.store.setSitecoreData(initialState);
    }

    app.config.devtools = process.env.NODE_ENV === 'development';
    app.provide('jss', app.config.globalProperties.$jss);
    app.provide('storeContext', app.config.globalProperties.$store);

    return { app, router };
}
