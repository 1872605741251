import HospitalService from '@/services/HospitalService';

const createStore = app => {
    const state = {
        ziekenhuizen: {},
        pending: false,
        error: false
    };

    const getters = {
        getHealthcareProviders: state => state.ziekenhuizen,
        getZiekenhuizen: state => key => state.ziekenhuizen[key] || null,
        getPending: state => state.pending,
        getError: state => state.error
    };

    const actions = {
        /**
         * Zoeken van ziekenhuizen in store of anders van server.
         *
         * @param {string}   pakket        De verkorte code van het pakket (zvw, zvwl).
         * @param {string}   locatie       De locatie voor het zoeken.
         *
         * @return {type} Return lijst van ziekenhuizen met contractinformatie
         */
        async findHospital({ getters, dispatch, commit }, { pakket, locatie }) {
            const key = `${pakket}|${locatie}`;
            commit('SET_ERROR', false);

            // check bestaande zoekenhuizen
            let result = getters.getZiekenhuizen(key);
            if (result) return result;

            // opnieuw zoeken
            result = await dispatch('getContent', { pakket, locatie });
            return result;
        },

        /**
         * Ophalen van ziekenhuizen van de server.
         *
         * Gebruikt de call ophalenziekenhuizen van de salesfunnel client controller
         * voor het ophalen van contractinformatie voor ziekenhuizen voor het opgegeven
         * pakker en in de buurt van de opgegeven locatie.
         *
         * @param {string}   pakket        De verkorte code van het pakket (zvw, zvwl).
         * @param {string}   locatie       De locatie voor het zoeken.
         *
         * @return {type} Return lijst met ziekenhuizen met contractinformatie
         */
        async getContent({ rootGetters, commit }, { pakket, locatie }) {
            commit('START_REQUEST');

            let ziekenhuizen;
            try {
                const hospitalContent = await HospitalService.getHospitals({
                    postal: locatie,
                    insurance: pakket,
                    brand: rootGetters['renderInfo/getPropositie']
                });

                // opslaan ziekenhuizen in store
                if (hospitalContent.zorginstellingen.length > 0) {
                    ziekenhuizen = {
                        lat: hospitalContent.lat,
                        lng: hospitalContent.lng,
                        zorginstellingen: hospitalContent.zorginstellingen
                    };

                    commit('SET_ZIEKENHUIZEN', { pakket, locatie, ziekenhuizen });
                }
            } catch (error) {
                commit('SET_ERROR', true);
            }

            commit('END_REQUEST');
            return ziekenhuizen || null;
        }
    };

    const mutations = {
        START_REQUEST: state => {
            state.pending = true;
            state.error = false;
        },
        END_REQUEST: state => {
            state.pending = false;
        },
        SET_ZIEKENHUIZEN: (state, { pakket, locatie, ziekenhuizen }) => {
            const key = `${pakket}|${locatie}`;
            state.ziekenhuizen = { ...state.ziekenhuizen, [key]: ziekenhuizen };
        },
        SET_PENDING(state, value) {
            state.pending = value;
        },
        SET_ERROR(state, value) {
            state.error = value;
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const hospitalsStoragePaths = ['hospitals'];

export default app => createStore(app);
